@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.landing-picture-container {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #003366;
}

.landing-picture {
    background: url('../../assets/images/banner_leuven_library.jpg') no-repeat center center;    
    background-size: cover;
    width: calc(100% - 100px);
    min-height: calc(25vw + 200px); /* Responsive height based on viewport width */
}

.oblique-bar {
    position: absolute;
    width: calc(57vw); /* Responsive width based on viewport width */
    height: 100%; /* Make sure the bars cover the full height */
    background-color: #004080;
    transform: skewX(15deg);
    z-index: 0;
}

.bar1 {
    top: 0;
    left: -15%; /* Adjust based on skew */
}

.bar2 {
    top: 0;
    left: -10%; /* Slightly shifted horizontally */
    background-color: rgba(0, 64, 128, 0.5); /* Same color but with transparency */
}

.HomeTitleCont {
    display: flex;
    width: calc(50vw); /* Responsive width based on viewport width */
    height: calc(20vw + 100px); /* Adjust height to be responsive as well */
}

.landing-text-container {
    display: flex;
    flex-direction: column;
    z-index: 1;
    /* margin-left:300px; */
}

.landing-text-container p {
    color: white;
    margin: 1.1% 0px;
    text-align: center;
    font-weight: bold;
    transform: translate(10%, -10%);
}

.landing-title {
    /* font-size: calc(4vw); */
    font-size: clamp(1.5rem, 3.5vw, 5rem);
    /* padding-bottom: 1px; */
    font-family: 'Recoleta', sans-serif;
    /* Spacing between lines*/
    line-height: 1.3;
}

.landing-location, .landing-date {
    font-size: clamp(0.5rem, 1.9vw, 3rem);
    margin-bottom: 15px;
    font-family: 'Lato', sans-serif;
}






/* Register to newsletter button */
.register-button-container {
    display: flex;
    justify-content: center;
    margin: 30px 0px;
}
.register-button-link:hover {
    background-color: #004080;
    transform: scale(1.05);
}
.register-button-link {
    background-color: #004080;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 130%;
    font-weight: 600;
    padding: 10px 20px;
    text-decoration: none;
    transition: background-color .3s ease,transform .3s ease;
}







/* Responsive adjustments */
@media (max-width: 1200px) {
    .landing-picture {
        min-height: calc(35vw + 150px); /* Adjust for larger screens */
    }

    .oblique-bar {
        width: calc(55vw);
    }

    .bar1 {
        left: -20%; /* Adjust based on skew */
    }

    .HomeTitleCont {
        width: calc(28vw);
    }

    .landing-text-container p {
        transform: translate(30%, -10%);
    }

    .landing-title {
        font-size: calc(4.5vw);
    }

    .landing-location, .landing-date {
        font-size: calc(3.2vw);
    }
}

@media (max-width: 768px) {
    .landing-picture {
        min-height: calc(40vw + 100px); /* Adjust height for tablets */
    }

    .oblique-bar {
        width: calc(60vw); /* Adjust width for smaller screens */
    }

    .bar1 {
        left: -30%; /* Adjust based on skew */
    }

    .HomeTitleCont {
        width: calc(20vw); /* Increase width for smaller screens */
    }

    .landing-text-container p {
        transform: translate(30%, -10%);
    }

    .landing-title {
        font-size: calc(6vw);
    }

    .landing-location, .landing-date {
        font-size: calc(4.5vw);
    }
}

@media (max-width: 480px) {
    .landing-picture {
        min-height: calc(50vw + 80px); /* Adjust height for mobile devices */
    }

    .oblique-bar {
        width: calc(55vw); /* Further adjust for small screens */
    }

    .bar1 {
        left: -20%; /* Adjust based on skew */
    }

    .bar2{
        left: 20%; /* Slightly shifted horizontally */
    }

    .HomeTitleCont {
        width: calc(20vw); /* Increase width for very small screens */
        height: calc(25vw + 100px); /* Adjust height for small screens */
    }

    .landing-text-container p {
        transform: translate(20%, -10%);
    }

    .landing-title {
        font-size: calc(7.5vw);
    }

    .landing-location, .landing-date {
        font-size: calc(5vw);
    }
}






.image-timeline {
    max-height: 500px;
    max-width: 350px !important;
}

.image-timeline img {
    width: 70% !important;
    max-width: 350px;
}

.kuleuven600-logo {
    max-height: 120px;
}
