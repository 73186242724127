.register-page-button {
    max-width: 300px;
    margin: 50px auto 0px auto;
}

.registration-info {
    padding: 20px;
    margin-bottom: 20px;
}

.registration-info h2 {
    margin-top: 35px;
}

th, td {
    padding: 10px;
    text-align: left;
    align-content: start
}

.twocols-container div {
    max-width: 800px !important;
}